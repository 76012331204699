import React, { useState, useEffect } from "react";
import { Menu, X } from "lucide-react";

const Sidebar = ({ scrollToPage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const links = [
    { label: "Home", page: "home", href: "/" },
    { label: "About", page: "about", href: "/about" },
    { label: "Team", page: "last", href: "/team" },
    { label: "Services", page: "services", href: "/services" },
    { label: "Portfolio", page: "portfolio", href: "/portfolio" },
    { label: "Blog", page: "blog", href: "/blog" },
    { label: "Contact", page: "last-vertical", href: "/contact" },
  ];

  return (
    <div className="relative">
      {/* Toggle Button */}
      <button
        onClick={toggleSidebar}
        className="fixed top-4 right-4 z-50 p-2 bg-[rgba(0,0,0,0.6)] text-[#e3ddc8] rounded-full"
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full bg-[rgba(0,0,0,0.8)] text-[#e3ddc8] z-40 transition-all duration-300 ease-in-out overflow-y-auto flex flex-col justify-center
          ${isMobile ? (isOpen ? "w-64" : "w-0") : "w-16"}
          ${isMobile && !isOpen ? "-translate-x-full" : "translate-x-0"}`}
      >
        <div className="flex flex-col text-[#e3ddc8] items-center space-y-5">
          {links.map(({ label, page, href }) => (
            <a
              key={page}
              href={href}
              className="fontmycustom text-[#e3ddc8] transition-colors duration-300 text-center w-full"
              onClick={(e) => {
                e.preventDefault();
                scrollToPage(page);
                if (isMobile) setIsOpen(false);
              }}
            >
              {isMobile && isOpen ? label : label.charAt(0)}
            </a>
          ))}
        </div>
      </div>

      {/* Backdrop */}
      {isMobile && isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};

export default Sidebar;

// import React, { useState, useEffect } from "react";
// import { Menu, X } from "lucide-react";

// const Sidebar = ({ scrollToPage }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };
//     handleResize();
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   const links = [
//     { label: "Home", page: "home", href: "/" },
//     { label: "About", page: "about", href: "/about" },
//     { label: "Team", page: "last", href: "/team" },
//     { label: "Services", page: "services", href: "/services" },
//     { label: "Portfolio", page: "portfolio", href: "/portfolio" },
//     { label: "Blog", page: "blog", href: "/blog" },
//     { label: "Contact", page: "last-vertical", href: "/contact" },
//   ];

//   return (
//     <div className="relative">
//       {/* Debug Info */}
//       {/* <div className="fixed top-0 left-0 bg-yellow-200 p-2 z-50">
//         isMobile: {isMobile ? "true" : "false"}, isOpen:{" "}
//         {isOpen ? "true" : "false"}
//       </div> */}

//       {/* Toggle Button */}
//       <button
//         onClick={toggleSidebar}
//         className="fixed top-4 right-4 z-50 p-2 bg-[rgba(0,0,0,0.6)] text-[#e3ddc8] rounded-full"
//       >
//         {isOpen ? <X size={24} /> : <Menu size={24} />}
//       </button>

//       {/* Sidebar */}
//       <div
//         className={`fixed top-0 left-0 h-full bg-[rgba(0,0,0,0.6)] text-[#e3ddc8] z-40 transition-all duration-300 ease-in-out overflow-y-auto
//           ${isMobile ? (isOpen ? "w-64" : "w-0") : "w-16"}
//           ${isMobile && !isOpen ? "-translate-x-full" : "translate-x-0"}`}
//       >
//         <div className="flex flex-col text-[#e3ddc8] items-center py-4 space-y-5">
//           {links.map(({ label, page, href }) => (
//             <a
//               key={page}
//               href={href}
//               className="fontmycustom text-[#e3ddc8] transition-colors duration-200"
//               onClick={(e) => {
//                 e.preventDefault();
//                 scrollToPage(page);
//                 if (isMobile) setIsOpen(false);
//               }}
//             >
//               {isMobile && isOpen ? label : label.charAt(0)}
//             </a>
//           ))}
//         </div>
//       </div>

//       {/* Backdrop */}
//       {isMobile && isOpen && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-50 z-30"
//           onClick={toggleSidebar}
//         ></div>
//       )}
//     </div>
//   );
// };

// export default Sidebar;

// //working for hover effect
// import React from "react";
// import logo from "../assets/webdaddy-final-logo.png";

// const Sidebar = ({ scrollToPage }) => {
//   const handleClick = (e, pageNumber) => {
//     e.preventDefault();
//     scrollToPage(pageNumber);
//   };

//   const links = [
//     { label: "Home", page: "home", href: "/" },
//     { label: "About", page: "about", href: "/about" },
//     { label: "Team", page: "last", href: "/team" },
//     { label: "Services", page: "services", href: "/services" },
//     { label: "Portfolio", page: "portfolio", href: "/portfolio" },
//     { label: "Blog", page: "blog", href: "/blog" },
//     { label: "Contact", page: "last-vertical", href: "/contact" },
//   ];

//   return (
//     <div className="sidebar fixed top-0 left-0 h-full bg-[#333333] z-20 flex flex-col items-center py-4 space-y-5 w-15">
//       <div className="mb-8 flex flex-col items-center">
//         <div className="w-15 h-15 flex items-center justify-center">
//           <img
//             src={logo}
//             alt="W"
//             className="cursor-pointer w-full h-full object-contain"
//             onClick={(e) => handleClick(e, "home")}
//           />
//         </div>
//       </div>
//       {links.map(({ label, page, href }) => (
//         <div key={page} className="relative group">
//           {label !== "Home" && (
//             <div className="w-1.5 h-1.5 bg-[#26d4b4] mb-2 ml-1 rounded-full transition-opacity duration-200 group-hover:opacity-0"></div>
//           )}
//           <a
//             href={href}
//             className="text-[#e3ddc8] hover:text-[#26d4b4] sidebaricon fontmycustom"
//             onClick={(e) => handleClick(e, page)}
//           >
//             {label.charAt(0)} {/* Display the first letter of the label */}
//           </a>
//           <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-0.5 w-20  text-[#e2dcc8] text-center text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
//             {label}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Sidebar;
