import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import ProgressBar from "./components/ProgressBar";
import Page1 from "./components/Page1";
import Page2 from "./components/Page2";
import Page3 from "./components/Page3";
import Page4 from "./components/Page4";
import About from "./components/About";
import Page5 from "./components/Page5";
import Loader from "./components/Loader";
import "./styles/App.css";

function AppContent() {
  const containerRef = useRef(null);
  const page5Ref = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [verticalScrollPosition, setVerticalScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isInServicesSection, setIsInServicesSection] = useState(false);
  const [cursorOverRightSide, setCursorOverRightSide] = useState(false);
  const [touchStartY, setTouchStartY] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    const updateScrollLimits = () => {
      setMaxScroll(containerRef.current.scrollWidth - window.innerWidth);
    };

    updateScrollLimits();
    window.addEventListener("resize", updateScrollLimits);

    return () => {
      window.removeEventListener("resize", updateScrollLimits);
    };
  }, []);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, [isInitialRender]);

  useEffect(() => {
    const handleVerticalScroll = () => {
      if (page5Ref.current) {
        setVerticalScrollPosition(page5Ref.current.scrollTop);

        const servicesSection =
          page5Ref.current.querySelector("#services-section");
        if (servicesSection) {
          const servicesSectionTop =
            servicesSection.offsetTop - page5Ref.current.offsetTop;
          const servicesSectionBottom =
            servicesSectionTop + servicesSection.offsetHeight;
          setIsInServicesSection(
            page5Ref.current.scrollTop >= servicesSectionTop &&
              page5Ref.current.scrollTop < servicesSectionBottom
          );
        }
      }
    };

    if (page5Ref.current) {
      page5Ref.current.addEventListener("scroll", handleVerticalScroll);
    }

    return () => {
      if (page5Ref.current) {
        page5Ref.current.removeEventListener("scroll", handleVerticalScroll);
      }
    };
  }, []);

  const smoothScroll = (delta) => {
    if (isMobile) {
      const direction = delta > 0 ? 1 : -1;

      // If we're on Page5 (index 5) and scrolling up
      if (currentPage === 6 && direction === -1) {
        if (page5Ref.current) {
          const sections = ["contact", "blog", "portfolio", "services", "team"];
          // const sections = ["contact", "blog", "portfolio", "services"];
          const currentScrollTop = page5Ref.current.scrollTop;

          // Find the current section
          let currentSectionIndex = -1;
          sections.forEach((section, index) => {
            const element = page5Ref.current.querySelector(
              `#${section}-section`
            );
            if (element && currentScrollTop >= element.offsetTop - 50) {
              // Added threshold
              currentSectionIndex = index;
            }
          });

          // If we're at the top of the first visible section
          if (currentScrollTop <= 50) {
            // Added threshold for top
            // Move to the previous page
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            const newPosition = (newPage - 1) * window.innerWidth;
            setScrollPosition(Math.min(newPosition, maxScroll));
            return;
          }

          // If we found the current section, scroll to the previous one
          if (
            currentSectionIndex >= 0 &&
            currentSectionIndex < sections.length - 1
          ) {
            const targetSection = page5Ref.current.querySelector(
              `#${sections[currentSectionIndex + 1]}-section`
            );
            if (targetSection) {
              page5Ref.current.scrollTo({
                top: targetSection.offsetTop,
                behavior: "smooth",
              });
            }
          }
        }
      } else {
        // Handle regular page navigation
        const newPage = Math.max(1, Math.min(currentPage + direction, 6));
        setCurrentPage(newPage);
        const newPosition = (newPage - 1) * window.innerWidth;
        setScrollPosition(Math.min(newPosition, maxScroll));
      }
    } else {
      // Desktop scrolling logic
      const newPosition = Math.max(
        0,
        Math.min(scrollPosition + delta, maxScroll)
      );
      setScrollPosition(newPosition);
    }
  };

  // const scrollToPage = (pageNumber) => {
  //   let newPosition;
  //   let newPage;

  //   switch (pageNumber) {
  //     case "home":
  //       newPosition = 0;
  //       newPage = 1;
  //       break;
  //     case "about":
  //       newPosition = window.innerWidth * 4;
  //       newPage = 5;
  //       break;
  //     case "team":
  //     case "services":
  //     case "portfolio":
  //     case "blog":
  //     case "last":
  //       newPosition = maxScroll;
  //       newPage = 6;
  //       break;
  //     default:
  //       if (typeof pageNumber === "number") {
  //         newPosition = Math.min(
  //           (pageNumber - 1) * window.innerWidth,
  //           maxScroll
  //         );
  //         newPage = pageNumber;
  //       } else {
  //         newPosition = 0;
  //         newPage = 1;
  //       }
  //   }

  //   setScrollPosition(newPosition);
  //   setCurrentPage(newPage);

  //   // Handle scrolling within Page5 if necessary
  //   if (
  //     pageNumber === "team" ||
  //     pageNumber === "services" ||
  //     pageNumber === "portfolio" ||
  //     pageNumber === "blog"
  //   ) {
  //     setTimeout(() => {
  //       if (page5Ref.current) {
  //         const targetSection = page5Ref.current.querySelector(
  //           `#${pageNumber}-section`
  //         );
  //         if (targetSection) {
  //           page5Ref.current.scrollTop =
  //             targetSection.offsetTop - page5Ref.current.offsetTop;
  //         }
  //       }
  //     }, 100);
  //   }
  // };

  const scrollToPage = (pageNumber) => {
    let newPosition;
    let newPage;

    switch (pageNumber) {
      case "home":
        newPosition = 0;
        newPage = 1;
        break;
      case "about":
        newPosition = window.innerWidth * 4;
        newPage = 5;
        break;
      case "team":
        newPosition = window.innerWidth * 5;
        newPage = 6;
        if (page5Ref.current) {
          setTimeout(() => {
            const teamSection = page5Ref.current.querySelector("#team-section");
            if (teamSection) {
              page5Ref.current.scrollTo({
                top: 0, // Scroll to top for team section
                behavior: "smooth",
              });
            }
          }, 100);
        }
        break;
      case "contact":
        newPosition = window.innerWidth * 5;
        newPage = 6;
        if (page5Ref.current) {
          setTimeout(() => {
            const contactSection =
              page5Ref.current.querySelector("#contact-section");
            if (contactSection) {
              page5Ref.current.scrollTo({
                top: contactSection.offsetTop,
                behavior: "smooth",
              });
            }
          }, 100);
        }
        break;
      case "services":
      case "portfolio":
      case "blog":
        newPosition = window.innerWidth * 5;
        newPage = 6;
        if (page5Ref.current) {
          setTimeout(() => {
            const targetSection = page5Ref.current.querySelector(
              `#${pageNumber}-section`
            );
            if (targetSection) {
              page5Ref.current.scrollTo({
                top: targetSection.offsetTop,
                behavior: "smooth",
              });
            }
          }, 100);
        }
        break;
      default:
        if (typeof pageNumber === "number") {
          newPosition = Math.min(
            (pageNumber - 1) * window.innerWidth,
            maxScroll
          );
          newPage = pageNumber;
        } else {
          newPosition = 0;
          newPage = 1;
        }
    }

    setScrollPosition(newPosition);
    setCurrentPage(newPage);

    // Handle scrolling within Page5 if necessary
    if (
      pageNumber === "team" ||
      pageNumber === "services" ||
      pageNumber === "portfolio" ||
      pageNumber === "blog"
    ) {
      setTimeout(() => {
        if (page5Ref.current) {
          const targetSection = page5Ref.current.querySelector(
            `#${pageNumber}-section`
          );
          if (targetSection) {
            page5Ref.current.scrollTop =
              targetSection.offsetTop - page5Ref.current.offsetTop;
          }
        }
      }, 100);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (page5Ref.current && isInServicesSection) {
        const servicesSection =
          page5Ref.current.querySelector("#services-section");
        if (servicesSection) {
          const rightSideContent =
            servicesSection.querySelector(".custom-scrollbar");
          if (rightSideContent) {
            const rect = rightSideContent.getBoundingClientRect();
            setCursorOverRightSide(
              e.clientX >= rect.left &&
                e.clientX <= rect.right &&
                e.clientY >= rect.top &&
                e.clientY <= rect.bottom
            );
          }
        }
      } else {
        setCursorOverRightSide(false);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isInServicesSection]);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      const isLastPage = scrollPosition >= maxScroll - 10;
      const delta = e.deltaY * (isMobile ? 3 : 1); // Increase the delta on mobile for faster scroll

      if (isLastPage && page5Ref.current) {
        const newScrollTop = page5Ref.current.scrollTop + delta;
        if (
          newScrollTop >= 0 &&
          newScrollTop <=
            page5Ref.current.scrollHeight - page5Ref.current.clientHeight
        ) {
          page5Ref.current.scrollTop = newScrollTop;
        }
      } else {
        smoothScroll(delta);
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [scrollPosition, maxScroll, isInServicesSection, cursorOverRightSide]);

  useEffect(() => {
    const handleTouchMove = (e) => {
      e.preventDefault();
      if (touchStartY === null) return;

      const touchEndY = e.touches[0].clientY;
      const deltaY = (touchStartY - touchEndY) * 1; // Increase scroll speed for mobile
      // const deltaY = (touchStartY - touchEndY) * 1.5;

      if (isMobile) {
        if (Math.abs(deltaY) > 30) {
          smoothScroll(deltaY);
          setTouchStartY(null);
        }
      } else {
        const isLastPage = scrollPosition >= maxScroll - 5;
        if (isLastPage && page5Ref.current) {
          page5Ref.current.scrollTop += deltaY;
        } else {
          smoothScroll(deltaY);
        }
      }

      setTouchStartY(touchEndY);
    };

    const handleTouchStart = (e) => {
      setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchEnd = () => {
      setTouchStartY(null);
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      containerRef.current.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      containerRef.current.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener(
          "touchstart",
          handleTouchStart
        );
        containerRef.current.removeEventListener("touchmove", handleTouchMove);
        containerRef.current.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [scrollPosition, maxScroll, touchStartY, isMobile, currentPage]);

  useEffect(() => {
    const handlePage5TouchMove = (e) => {
      if (scrollPosition >= maxScroll - 10 && page5Ref.current) {
        const touch = e.touches[0];
        const deltaY = touch.clientY - (touchStartY || touch.clientY);

        if (isInServicesSection && cursorOverRightSide) {
          // Handle scrolling within the services section
          const rightSideContent = page5Ref.current.querySelector(
            "#services-section .custom-scrollbar"
          );
          if (rightSideContent) {
            rightSideContent.scrollTop += -deltaY;
          }
        } else if (page5Ref.current.scrollTop > 0 || deltaY < 0) {
          // Handle vertical scrolling on Page5
          // page5Ref.current.scrollTop += -deltaY;
          page5Ref.current.scrollTop += -deltaY * 3; // Make scrolling faster by multiplying deltaY
        }

        setTouchStartY(touch.clientY);
      }
    };

    if (page5Ref.current) {
      page5Ref.current.addEventListener("touchmove", handlePage5TouchMove, {
        passive: false,
      });
    }

    return () => {
      if (page5Ref.current) {
        page5Ref.current.removeEventListener("touchmove", handlePage5TouchMove);
      }
    };
  }, [
    scrollPosition,
    maxScroll,
    touchStartY,
    isInServicesSection,
    cursorOverRightSide,
  ]);

  return (
    <Router basename="/mobile">
    {/* <Router basename="/mobile"> */}
      <div className="font-sans overflow-hidden flex h-full bg-[#f7f7f7]">
        <Sidebar scrollToPage={scrollToPage} />
        <ProgressBar
          scrollPosition={scrollPosition}
          verticalScrollPosition={verticalScrollPosition}
          maxScroll={maxScroll}
          page5Ref={page5Ref}
        />
        <div className="flex-grow overflow-hidden">
          <div
            ref={containerRef}
            className={`flex w-[600vw] h-screen ${
              isInitialRender
                ? ""
                : "transition-transform duration-500 ease-out"
            }`}
            style={{ transform: `translateX(-${scrollPosition}px)` }}
          >
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 />
            <About />
            <Page5 ref={page5Ref} />
          </div>
        </div>
      </div>
    </Router>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return <>{loading ? <Loader /> : <AppContent />}</>;
}

export default App;
